import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskLocationFragmentDoc } from './taskLocationFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type CreateTaskLocationMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  input: Types.TaskLocationInput;
}>;

export type CreateTaskLocationMutation = {
  __typename?: 'Mutation';
  createTaskLocation: {
    __typename?: 'TaskLocation';
    id: string;
    task_location_type_id?: string | null;
    street?: string | null;
    street_no?: string | null;
    postcode?: string | null;
    city?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    client_location_id?: string | null;
    country: { __typename?: 'Country'; id: string; title: string };
  };
};

export const CreateTaskLocationDocument = gql`
  mutation CreateTaskLocation($task_id: ID!, $input: TaskLocationInput!) {
    createTaskLocation(task_id: $task_id, input: $input) {
      ...TaskLocation
    }
  }
  ${TaskLocationFragmentDoc}
`;

export function useCreateTaskLocationMutation() {
  return Urql.useMutation<CreateTaskLocationMutation, CreateTaskLocationMutationVariables>(
    CreateTaskLocationDocument,
  );
}
