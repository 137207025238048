import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { Permission } from '@generated/graphql.generated';
import { useCallback, useMemo } from 'react';

import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, InvoiceAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface InvoiceSubject {
  me: CurrentUserAbilityFragment;
  invoice?: InvoiceAbilityFragment;
}

type InvoiceSubjectArg = Partial<InvoiceSubject>;

type Actions =
  | 'viewInvoices'
  | 'readInvoice'
  | 'createInvoices'
  | 'deleteInvoice'
  | 'updateInvoice'
  | 'completeInvoice'
  | 'setInvoicePaid'
  | 'cancelInvoice'
  | 'revertInvoicePaid'
  | 'syncInvoice'
  | 'editInvoiceReminder';
type Subjects = InvoiceSubject | 'InvoiceSubject';

export enum InvoiceActionType {
  READ = 'readInvoice',
  DELETE = 'deleteInvoice',
  UPDATE = 'updateInvoice',
  COMPLETE = 'completeInvoice',
  SET_PAID = 'setInvoicePaid',
  CANCEL = 'cancelInvoice',
  REVERT_PAID = 'revertInvoicePaid',
  SYNC = 'syncInvoice',
  EDIT_REMINDER = 'editInvoiceReminder',
}

type InvoiceAbility = Ability<[Actions, Subjects]>;
const invoiceAbility = Ability as AbilityClass<InvoiceAbility>;

export const useInvoiceAbility = (): [
  InvoiceAbility,
  (sub?: InvoiceSubjectArg) => InvoiceSubject & ForcedSubject<'InvoiceSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(invoiceAbility);
    // global permissions
    can('viewInvoices', 'InvoiceSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_INVOICE_INDEX] },
    });
    can('createInvoices', 'InvoiceSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_INVOICE_CREATE] },
    });
    can('readInvoice', 'InvoiceSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_INVOICE_READ] },
    });

    // invoice specific permissions
    can('syncInvoice', 'InvoiceSubject', {
      'invoice.permissions': { $in: [Permission.TASK_INVOICE_SYNC] },
    });
    can('deleteInvoice', 'InvoiceSubject', {
      'invoice.permissions': { $in: [Permission.TASK_INVOICE_DELETE] },
    });
    can('updateInvoice', 'InvoiceSubject', {
      'invoice.permissions': { $in: [Permission.TASK_INVOICE_UPDATE] },
    });
    can('editInvoiceReminder', 'InvoiceSubject', {
      'invoice.permissions': { $in: [Permission.TASK_INVOICE_UPDATE] },
    });
    can('completeInvoice', 'InvoiceSubject', {
      'invoice.permissions': { $in: [Permission.TASK_INVOICE_COMPLETE] },
    });
    can('setInvoicePaid', 'InvoiceSubject', {
      'invoice.permissions': { $in: [Permission.TASK_INVOICE_SET_PAID] },
    });
    can('cancelInvoice', 'InvoiceSubject', {
      'invoice.permissions': { $in: [Permission.TASK_INVOICE_CANCEL] },
    });
    can('revertInvoicePaid', 'InvoiceSubject', {
      'invoice.permissions': { $in: [Permission.TASK_INVOICE_REVERT_PAID] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: InvoiceSubjectArg) => {
      return subjectHelper('InvoiceSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

type CanActivityProps = GenericCanSharedProps<Actions>;

export const CanInvoice = (props: CanActivityProps) => {
  const [invoiceAbility, invoiceSubject] = useInvoiceAbility();

  return (
    <GenericCan<Actions, Subjects, InvoiceAbility>
      ability={invoiceAbility}
      subject={invoiceSubject()}
      {...props}
    />
  );
};
