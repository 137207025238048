import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { CalendarEventTypeFragmentDoc } from '../../../events/graphql/generated/calendarEventTypeFragment.generated';
import { CalendarEventLocationFragmentDoc } from '../../../events/graphql/generated/calendarEventFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskAppointmentQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID'];
}>;

export type TaskAppointmentQuery = {
  __typename?: 'Query';
  task: {
    __typename?: 'Task';
    id: string;
    identifier?: string | null;
    external_number?: string | null;
    permissions: Array<Types.Permission>;
    appointment?: {
      __typename?: 'TaskEvent';
      id: string;
      calendar_event?: {
        __typename?: 'CalendarEvent';
        id: string;
        title: string;
        description?: string | null;
        date_from: string;
        date_to: string;
        is_all_day: boolean;
        task_id?: string | null;
        permissions: Array<Types.Permission>;
        type: {
          __typename?: 'CalendarEventType';
          id: string;
          title: string;
          color: string;
          type_id: Types.CalendarEventTypeId;
        };
      } | null;
    } | null;
    primary_form?: {
      __typename?: 'TaskForm';
      id: string;
      template: {
        __typename?: 'TaskTemplate';
        id: string;
        estimated_appointment_time_seconds: number;
      };
    } | null;
    primary_location?: {
      __typename?: 'TaskLocation';
      city?: string | null;
      postcode?: string | null;
      street_full?: string | null;
    } | null;
  };
};

export const TaskAppointmentDocument = gql`
  query TaskAppointment($taskId: ID!) {
    task(id: $taskId) {
      id
      identifier
      external_number
      appointment {
        id
        calendar_event {
          id
          type {
            ...CalendarEventType
          }
          title
          description
          date_from
          date_to
          is_all_day
          task_id
          permissions
        }
      }
      primary_form {
        id
        template {
          id
          estimated_appointment_time_seconds
        }
      }
      primary_location {
        ...CalendarEventLocation
      }
      permissions
    }
  }
  ${CalendarEventTypeFragmentDoc}
  ${CalendarEventLocationFragmentDoc}
`;

export function useTaskAppointmentQuery(
  options: Omit<Urql.UseQueryArgs<TaskAppointmentQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskAppointmentQuery, TaskAppointmentQueryVariables>({
    query: TaskAppointmentDocument,
    ...options,
  });
}
