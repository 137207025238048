export default {
  labels: {
    type_id: 'Typ',
    client_location_type_id: 'Niederlassungstyp',
    client_location_id: 'Niederlassung',
    login_possible: 'Portal Login möglich',
    leader_client_id: 'Teamleiter',
    bank_name: 'Bank',
    bank_code: 'BIC',
    iban: 'IBAN',
    external_identifier: 'Externe Id',
    payout_note: 'Anmerkungen für die Bezahlung',
    payout_note_tooltip:
      'Diese Anmerkung wird bei der Rechnungslegung angezeigt und gibt Hinweise zur detaillierten Abrechnung',
    account_number: 'Konto Nummer',
    vat_number: 'USt-ID',
    accounts_receivable_number: 'Debitorennummer',
    eligible_tax_deduction: 'Vorsteuerabzugsberechtigt',
    notes: 'Anmerkungen',
    assignment_notes: 'Anmerkung Zuweisung',
    location_notes: 'Anmerkungen zum Standort',
    type: 'Typ',
    callClient: 'Anrufen: {{phone}}',
    mailToClient: 'Email an: {{email}}',
    contacts: 'Kontakte',
    payoutAmount: 'Auszahlungsbetrag',
    payoutPercentage: 'Auszahlungsprozentsatz',
    leaderPayoutAmount: 'Auszahlungsbetrag des Teamleiters',
    leaderPayoutPercentage: 'Auszahlungsprozentsatz des Teamleiters',
    businessInsurance: 'Geschäftsversicherung',
    hasIpad: 'IPad ist vorhanden',
    canSendEmails: 'Kann E-Mails senden',
    payoutType: 'Auszahlungsart',
    payoutTypeTax: 'Abzug von Steuern',
    state_id: 'Status',
    state_notes: 'Status-Notiz',
    useAlternateAddress: 'Alternative-Rechnungsadresse aktivieren',
    paymentTargetDays: 'Zahlungsziel in Tagen',
    hasPayout: 'Auszahlungen aktiv',
    damage_number_mask: 'Schadennummer Maske',
    term_of_payment: 'Zahlungsziel in Tagen',
    dateValidation: 'Das Startdatum muss vor dem Enddatum liegen',
    different_policy_holder: 'Abweichender Versicherungsnehmer',
    different_insurance_policy_number: 'Abweichende Versicherungsscheinnummer',
    external_number: 'Externe Nummer',
    external_client_id: 'Externe Kunden Nummer',
    availableColumns: 'Verfügbare Spalten',
    selectedColumns: 'Gewählte Spalten',
    is_absent: 'abwesend',
  },
  grids: {
    columns: {
      id: 'Id',
      type_id: 'Typ',
    },
  },
  states: {
    active: 'Aktiv',
    inactive: 'Inaktiv',
    disabled: 'Gesperrt',
  },
  errors: {
    not_found: {
      title: 'Klient nicht gefunden',
      note: 'Der von Ihnen angeforderte Klient wurde nicht gefunden',
    },
  },
  notifications: {
    clientAbsenceCreate: {
      error: 'Die Abwesenheit konnte nicht erstellt werden!',
      success: 'Abwesenheit erfolgreich erstellt',
    },
    clientCreate: {
      success: 'Stammdaten Eintrag wurde gespeichert!',
      error: 'Stammdaten Eintrag konnte nicht gespeichert werden!',
      validationError: 'Validation failure!',
      openEntry: 'Eintrag zum Bearbeiten öffnen',
    },
    clientDelete: {
      success: 'Klient wurde gelöscht!',
    },
    clientUpdate: {
      success: 'Klient wurde gespeichert!',
      error: 'Stammdaten Eintrag konnte nicht gespeichert werden!',
    },
    editClientQualifications: {
      success: 'Qualifikationen gespeichert!',
    },
    clientLocationCreate: {
      success: 'Niederlassung gespeichert!',
      validationError: 'Validation failure!',
    },
    clientLocationEdit: {
      success: 'Niederlassung gespeichert!',
      validationError: 'Validation failure!',
    },
    clientLocationDelete: {
      success: 'Niederlassung wurde gelöscht!',
    },
    contactCreate: {
      success: 'Kontakt wurde gespeichert!',
      validationError: 'Validation failure!',
    },
    contactDelete: {
      success: 'Kontakt wurde gelöscht!',
    },
    contactUpdate: {
      success: 'Kontakt wurde gespeichert!',
    },
    updateClientNotificationEmailSettings: {
      success: 'E-Mail Benachrichtigungs Einstellung gespeichert!',
      error: 'E-Mail Benachrichtigungs Einstellung konnten nicht gespeichert werden!',
    },
    upsertClientBanking: {
      success: 'Bank-Verbindung erfolgreich gespeichert!',
    },
    upsertClientTax: {
      success: 'Steuerdaten erfolgreich gespeichert!',
    },
    upsertClientPayroll: {
      success: 'Einstellungen zur Auszahlung sind erfolgreich gespeichert!',
    },
    upsertClientQualificationSuccess: 'Qualifikation erfolgreich gespeichert',
    upsertClientServiceLevelSuccess:
      'Einstellungen zum Service Level sind erfolgreich gespeichert!',
    upsertAdditionalInsuranceInput: {
      success: 'Einstellungen zur Versicherung wurden erfolgreich gespeichert!',
    },
    updateDisabledForms: {
      success: 'Formulareinstellungen wurden gespeichert.',
    },
    hideInsurances: {
      success: 'Gesperrte Versicherungen wurden gespeichert.',
    },
    updateClientQualifications: {
      success: 'Qualifikationen wurden gespeichert.',
    },
  },
  components: {
    clientMessagesTab: {
      availableTemplates: 'Verfügbare Vorlagen',
      selectedTemplates: 'Gewählte Vorlagen',
      header: {
        title: '2-Klick-Nachrichten',
        description:
          'Hier können Sie Nachrichten-Vorlagen auswählen, welche dann in Aufträgen mit nur 2 Klicks ausgesendet werden können.',
      },
      blocks: {
        emailTemplates: {
          title: 'Email-Vorlagen',
          description:
            'Wählen Sie die Email-Vorlagen, welche zum Versenden verwendet werden können.',
        },
        interfaceMessageTemplates: {
          title: 'Schnittstellen-Vorlagen',
          description:
            'Wählen Sie die Schnittstellen-Vorlagen, welche zum Versenden verwendet werden können.',
        },
      },
    },
    clientDetailCommonTab: {
      header: {
        title: 'Stammdaten',
        description: 'Hier können Sie die allgemeinen Stammdaten des Klienten bearbeiten.',
      },
      blocks: {
        common: {
          title: 'Basisdaten',
          description: 'Allgemeine Daten zum Klienten',
        },
        qualifications: {
          title: 'Qualifikationen',
          description: 'Hier können Sie die Qualifikationen des Regulierers bearbeiten.',
        },
        state: {
          title: 'Account Status',
          description: 'Informationen zum aktuellen Status',
        },
      },
    },
    clientDetailLocationsTab: {
      header: {
        title: 'Niederlassungen',
        description: 'Hier können Sie die Niederlassungen des Klienten bearbeiten.',
      },
    },
    clientDetailContactsTab: {
      header: {
        title: 'Kontakte',
        description: 'Hier können Sie die Kontakte des Klienten bearbeiten.',
      },
    },
    clientDetailAbsenceTab: {
      header: {
        title: 'Abwesenheit',
        description: 'Hier können Sie die Abwesenheit des Klienten bearbeiten.',
      },
    },
    clientDetailAddressTab: {
      header: {
        title: 'Anschrift',
        description: 'Hier können Sie die Adressdaten des Klienten bearbeiten.',
      },
      blocks: {
        address: {
          title: 'Adresse',
          description: 'Hauptadresse des Klienten',
        },
      },
    },
    clientBankingTab: {
      header: {
        title: 'Bank-Verbindung',
        description: 'Hier können Sie die Bankverbindung des Klienten bearbeiten.',
      },
      blocks: {
        common: {
          title: 'Bank-Verbindung',
          description: 'Bankverbindung des Klienten',
        },
        alternate: {
          title: 'Alternative-Rechnungsadresse',
          description:
            'Aktiviere Sie die alternative Rechnungsadresse, falls Sie beim automatischen Erzeugen von Rechnungen/Dokumenten diese Adresse verwenden möchten.',
        },
        paymentgoal: {
          title: 'Zahlungsziel',
          description:
            'Falls automatisierte Rechnungen für den Kundentyp erzeugt werden, wird automatisch dieses Zahlungsziel verwendet.',
        },
      },
      validation: {
        iban: 'Dies ist keine korrekte IBAN.',
      },
    },
    clientInsuranceTab: {
      header: {
        title: 'Erweitert',
        description: 'Hier können Sie spezifische Einstellungen zur Versicherung vornehmen',
      },
      blocks: {
        common: {
          title: 'Allgemein',
          description: 'Allgemeine Einstellungen',
        },
        taskExport: {
          title: 'Export',
          description:
            'Hier können Sie die Spalten für den Export wählen und deren Reihenfolge anpassen.',
          importStatisticExportConfigFromClient: 'Von Versicherung übernehmen',
          importStatisticExportConfigFromClientTooltip:
            'Hier können Sie eine Konfiguration von einer anderen Versicherung übernehmen. Die aktuell gewählten Spalten gehen verloren.',
          apply: 'Übernehmen',
        },
      },
    },
    clientTaxationTab: {
      header: {
        title: 'Steuerdaten',
        description: 'Hier können Sie die Steuerdaten des Klienten bearbeiten.',
      },
      blocks: {
        common: {
          title: 'Steuerdaten',
          description: 'Steuerdaten des Klienten',
        },
      },
    },
    payrollTab: {
      header: {
        title: 'Abrechnung',
        description:
          'Hier können Sie alle relevanten Einstellungen zur Abrechnung der Mitarbeiter einstellen',
      },
      blocks: {
        payout: {
          title: 'Auszahlung',
          description: 'Hier können Sie ihre Auszahlung aktivieren.',
        },
        property: {
          title: 'Sach',
          description: 'Hier finden Sie alle Einstellungen zur Auszahlung von Sach-Schäden',
        },
        liability: {
          title: 'Haftpflicht',
          description: 'Hier finden Sie alle Einstellungen zur Auszahlung von Haftpflicht-Schäden',
        },
        business: {
          title: 'Gewerbe',
          description: 'Hier finden Sie alle Einstellungen zur Auszahlung von Gewerbe-Schäden',
        },
        additional: {
          title: 'Erweitere Auszahlungsinformationen',
          description: 'Hier finden Sie noch weitere Einstellungen zur Abrechnung',
        },
        additionalInfo: {
          title: 'Erweiterte Informationen',
          description: 'Hier finden Sie weitere optionale Einstellungsmöglichkeiten',
        },
      },
      payoutType: {
        afterInvoice: 'nach Rechnungseingang',
        afterPayment: 'nach Bezahlung',
      },
      payoutTypeTax: {
        gross: 'Brutto',
        net: 'Netto',
      },
    },
    serviceLevelTab: {
      header: {
        title: 'Service Level',
        description:
          'Service-Level erzeugen automatisch Aufgaben, die im angebebenen Zeitraum erledigt sein müssen.',
      },
      blocks: {
        process: {
          description: 'Arbeitstagen (Mo-Fr)',
          acceptTask: 'Auftrag muss nach Zuweisung angenommen werden in',
          setAppointment: 'Wenn der Auftrag angenommen wurde, muss der Termin gesetzt werden in',
          setControlling:
            'Nach dem Ortstermin, muss der Auftrag (sofern Controlling aktiv ist) in (Tagen) zum Controlling',
          sendReport: 'Bericht muss nach dem Ortstermin verschickt werden in (Tagen)',
          setReworkDone: 'Nacharbeit muss erledigt sein in (Tagen)',
        },
      },
    },
    qualificationTab: {
      header: {
        title: 'Qualifikation',
        description:
          'Konfigurieren Sie hier Einstellungen bezüglich der Qualifikation dieses Benutzers',
      },
      blocks: {
        title: 'Qualifikationen',
        description: 'Geben sie verschiedene Schadens-Typen für den Klienten frei.',
        allowProperty: 'Darf Formulare vom Typ <bold>Sach</bold> bearbeiten',
        allowLiability: 'Darf Formulare vom Typ <bold>Haftpflicht</bold> bearbeiten',
        allowBusiness: 'Darf Formulare vom Typ <bold>Gewerbe</bold> bearbeiten',
        allowAdditional: 'Darf Formulare vom Typ <bold>Sonstige</bold> bearbeiten',
      },
      userSpecificBlocks: {
        title: 'Benutzerspezifische Qualifikationen',
        description:
          'Hier finden Sie Details wo man den Prozess des Benutzers individuell gestalten kann',
        canFinishTasks: 'Darf Aufträge selber, ohne Controlling abschließen',
        allowProposals: 'Darf Angebote erzeugen',
        allowInvoices: 'Darf Rechnungen erzeugen',
      },
      maxDistance: {
        title: 'Maximale Entfernung zum Auftrag',
        description: 'Hier können Sie die maximale Entfernung zum Auftrag einstellen.',
        distance: 'Entfernung in km',
      },
    },
    disabledFormsTab: {
      header: {
        title: 'Deaktivierte Formulare',
        description: 'Einzelne Formulare für den Regulierer deaktivieren',
      },
      blocks: {
        disabledForms: {
          listLabels: {
            enabled: 'Alle Formulare',
            disabled: 'Deaktivierte Formulare',
          },
        },
      },
    },
    hiddenInsurancesTab: {
      header: {
        title: 'Gesperrte Versicherungen',
        description: 'Hier können Sie den Regulierer für Versicherungen sperren',
      },
      blocks: {
        hiddenInsurances: {
          listLabels: {
            enabled: 'Alle Versicherungen',
            disabled: 'Gesperrte Versicherungen',
          },
        },
      },
    },
    invoicesTab: {
      header: {
        title: 'Rechnungen',
        description:
          'Hier sehen Sie eine Übersicht aller vom System erzeugten Rechnungen. Sie können über den Aktionen Button die Rechnungen öffnen.',
      },
    },
    accountTab: {
      title: 'Benutzer - {{user_id}}',
      description:
        'Hier können Sie die Benutzer-Daten z.b.(Passwörter, Benutzerrolle, Name, usw.) bearbeiten.',
      tabs: {
        common: 'Allgemein',
        password: 'Passwort',
        verify_email: 'E-Mail Bestätigung',
        role: 'Benutzerrolle',
      },
    },
    clientMetaData: {
      createdAtDate: 'Erstellt am',
      lastLoginData: 'Letzter Login',
    },
    clientStateId: {
      active: 'Aktiv',
      inactive: 'Inaktiv',
      disabled: 'Gesperrt',
    },
    notification_email_settings_tab: {
      header: {
        title: 'Benachrichtigungen',
        description: 'Wählen Sie, wie Versicherungen informiert werden sollen.',
      },
      edit: 'Einstellungen verwalten',
    },
  },
  dialogs: {
    updateQualifications: {
      title: 'Qualifikationen bearbeiten',
      description: 'Hier können Sie Qualifikationen zuweisen.',
    },
    clientCreate: {
      title: 'Neues Stammdaten-Objekt',
      note: 'Geben Sie die Informationen zu dem neuen Stammdaten Objekt an.',
    },
    clientImport: {
      title: 'Stammdaten importieren',
      note: 'Hier können Sie die Stammdaten importieren',
      import: 'Importieren',
      success: 'Erfolgreich importiert.',
      error: 'Fehler beim Import',
    },
    clientLegacyImport: {
      title: 'Importiere Benutzer aus Rocketform',
      note: 'Hierbei können Benutzer aus dem Benutzer Export von Rocketform importiert werden',
      import: 'Importieren',
      success: 'Erfolgreich importiert.',
      error: 'Fehler beim Import',
    },
    editClientQualifications: {
      title: 'Qualifikationen bearbeiten',
      note: 'Bearbeiten Sie die Qualifikationen des Stammdaten-Objekts.',
    },
    clientExport: {
      title: 'Stammdaten herunterladen',
      note: 'Laden Sie die exportierten Stammdaten herunter',
    },
    clientDelete: {
      title: 'Klienten löschen',
      note: 'Wollen Sie den Klienten wirklich löschen?',
    },
    clientLocationCreate: {
      title: 'Niederlassung erstellen',
    },
    clientLocationDelete: {
      title: 'Niederlassung löschen',
      note: 'Wollen Sie die Niederlassung wirklich löschen?',
    },
    clientLocationEdit: {
      title: 'Niederlassung bearbeiten',
    },
    contactCreate: {
      title: 'Kontakt erstellen',
      note: 'Hier können Sie weitere Kontakte anlegen.',
    },
    contactEdit: {
      title: 'Kontakt bearbeiten',
      note: 'Hier können Sie die Kontaktdaten bearbeiten.',
    },
    contactDelete: {
      title: 'Kontakt löschen',
      note: 'Wollen Sie den Kontakt wirklich löschen?',
    },
    clientAbsenceCreate: {
      title: 'Neue Abwesenheit',
    },
    clientAbsenceDelete: {
      title: 'Abwesenheit löschen',
      note: 'Willen Sie diesen Eintrag wirklich löschen?',
    },
    editClientEmailNotificationType: {
      title: 'E-Mail Benachrichtigungen bearbeiten',
      note: 'Hier können Sie die E-Mail Benachrichtigungen bearbeiten.',
      email_template_id: 'E-Mail Vorlage',
    },
  },
  pages: {
    clientOverview: {
      title: 'Stammdaten - Übersicht',
      description: 'Hier sehen Sie eine Liste von Regulierern, Teamleitern und Versicherungen.',
      toolbar: {
        create: 'Erstellen',
        import: 'Importieren',
        importRocket: 'Importiere Benutzer aus Rocketform',
      },
    },
    clientDetail: {
      title: '{{name}}',
      title_name: 'Stammdaten: {{name}}',
      description: 'Hier können Sie die Stammdaten des Kunden bearbeiten.',
      locationsCount: 'Anzahl Niederlassungen',
      contactsCount: 'Anzahl Kontakte',
      contactsCountBadge: '{{counter}} Kontakte',
      locationsCountBadge: '{{counter}} Niederlassungen',
      reason: 'Grund',
      tabs: {
        common: 'Allgemein',
        address: 'Anschrift',
        contacts: 'Kontakte',
        locations: 'Niederlassungen',
        banking: 'Bank-Verbindung',
        taxation: 'Steuern',
        absence: 'Abwesenheiten',
        payroll: 'Abrechnung',
        serviceLevel: 'Service Level',
        qualification: 'Qualifikation',
        disabledForms: 'Deaktivierte Formulare',
        hiddenInsurances: 'Gesperrte Versicherungen',
        account: 'Account',
        notification_email_settings: 'Benachrichtigungen',
        invoices: 'Rechnungen',
        insurance: 'Erweitert',
        policyHolder: 'Kunde',
        messages: '2-Klick-Nachrichten',
      },
      sections: {
        common: 'Allgemein',
        portal: 'Portal-Zugriff',
      },
      forms: {
        fieldsets: {
          common: 'Common',
          primaryLocation: 'Address',
        },
      },
    },
  },
};
