import './OfflineNotification.scss';

import { Notification } from '@progress/kendo-react-notification';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OfflineNotification = () => {
  const { t } = useTranslation();
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!isOffline) {
    return null;
  }

  return (
    <div className="OfflineNotification">
      <Notification
        type={{ style: 'error', icon: true }}
        closable
        onClose={() => setIsOffline(false)}
      >
        <p className="k-font-weight-semibold">{t('common.components.offlineNotification')}</p>
      </Notification>
    </div>
  );
};
