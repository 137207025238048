export default {
  labels: {
    insurance: 'Kunde',
    client: 'Kunde',
  },
  forms: {
    createTask: {
      fields: {
        client: {
          label: 'Kunde',
        },
        clientLocation: {
          label: 'Wohneinheit',
        },
        clientContact: {
          label: 'Mieter',
        },
        owner: {
          label: 'Monteur',
          noLocationHint: 'Trage einen Ort ein um alle Monteure zu sehen.',
        },
        parallelNumber: {
          label: 'KOS-Schaden',
        },
      },
    },
  },
};
