import { GridCellProps } from '@progress/kendo-react-grid';
import { get } from 'lodash';
import { useMemo } from 'react';

import { DateFormat, useFormatIsoDate, useTdElementProps } from '../../hooks';

export interface DateTimeCellProps {
  dateFormat?: DateFormat;
  addLineBreakAt?: string; // Optionally add a line break at each occurrence of the specified delimiter in the value
}

export const DateTimeCell = (props: DateTimeCellProps & GridCellProps) => {
  const tdElementProps = useTdElementProps(props);
  const { dateFormat = DateFormat.DateTime, addLineBreakAt } = props;
  const formatDate = useFormatIsoDate();
  const formatted = useMemo(() => {
    const fieldValue = get(props.dataItem, props.field ?? '');
    return fieldValue ? formatDate(fieldValue, dateFormat) : undefined;
  }, [dateFormat, formatDate, props.dataItem, props.field]);

  const parts = useMemo(() => {
    return formatted && addLineBreakAt ? formatted.split(addLineBreakAt) : [formatted];
  }, [formatted, addLineBreakAt]);

  return (
    <td {...tdElementProps}>
      {parts.map((part, index) => (
        <span key={index}>
          {part}
          {index < parts.length - 1 && <br />}
        </span>
      ))}
    </td>
  );
};
