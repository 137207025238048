export default {
  labels: {
    identifier: 'Invoice no.',
    externalNumber: 'Claim number',
    date: 'Invoice date',
    taskId: 'Order',
    address: 'Address',
    sumNet: 'Total Net',
    sumGross: 'Gross Total',
    stateId: 'Status',
    client: 'Customer',
    activate: 'Activate Invoice',
    setPaid: 'Set paid',
    revertPaid: 'Revert paid',
    cancelInvoice: 'Cancel Invoice',
    payDate: 'Pay date',
    overdueDate: 'Overdue date',
    total: 'Total:',
    note: 'Dunning status',
    reminder: 'Reminder sent',
    reminders: 'Send reminders',
    editReminder: 'Edit dunning status',
    clients: 'Insurances',
    exportDatev: 'Export data as CSV (DATEV)',
  },
  dialogs: {
    delete: {
      title: 'Delete invoice?',
      description: 'Are you sure you want to delete the invoice?',
    },
    setPaid: {
      title: 'Mark invoice as paid?',
      description: 'Are you sure you want to mark the invoice as paid?',
    },
    revertPaid: {
      title: 'Revert invoice paid?',
      description: 'Are you sure you want to revert the invoice payment status to open?',
    },
    cancelInvoice: {
      title: 'Cancel invoice?',
      description: 'Are you sure you want to cancel the invoice?',
    },
    payDate: 'Pay date',
    overdueDate: 'Overdue date',
    invoiceExport: {
      title: 'Export invoices',
      note: 'Download the exported invoices.',
    },
    exportDatev: {
      title: 'DATEV Export',
      description: 'Would you like to export the selected invoice in Datev format?',
      notifications: {
        success: 'Export successful.',
        error: 'An error has occurred during export.',
      },
    },
    multipleNotes: {
      title: 'Send reminders',
      description: 'Reminders are sent for the following invoice using the selected template:',
      notifications: {
        success: 'Reminders sent successfully.',
        error: 'Reminders could not be sent.',
      },
      labels: {
        remove: 'Remove',
        template: 'Select template',
        send: 'Send reminders',
        tooltip: 'Removes the invoice from this list only',
      },
    },
    editReminder: {
      title: 'Dunning status',
      reminder_note: 'Edit dunning status',
      reminder: 'Reminder sent',
      notifications: {
        error: 'Unfortunately, the dunning status could not be changed.',
        success: 'Dunning status successfully changed.',
      },
    },
  },
  pages: {
    overview: {
      title: 'Invoices',
      description:
        'Here you can see an overview of all invoices generated by the system. You can open the invoices via the actions button.',
    },
  },
};
