import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ClientQualificationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;

export type ClientQualificationQuery = {
  __typename?: 'Query';
  client?: {
    __typename?: 'Client';
    client_qualification?: {
      __typename?: 'ClientQualification';
      id: string;
      client_id: string;
      allow_property: boolean;
      allow_liability: boolean;
      allow_business: boolean;
      allow_additional: boolean;
      allow_invoices: boolean;
      allow_proposals: boolean;
      can_finish_tasks: boolean;
      max_distance_to_claim: number;
    } | null;
  } | null;
};

export const ClientQualificationDocument = gql`
  query ClientQualification($id: ID!) {
    client(id: $id) {
      client_qualification {
        id
        client_id
        allow_property
        allow_liability
        allow_business
        allow_additional
        allow_invoices
        allow_proposals
        can_finish_tasks
        max_distance_to_claim
      }
    }
  }
`;

export function useClientQualificationQuery(
  options: Omit<Urql.UseQueryArgs<ClientQualificationQueryVariables>, 'query'>,
) {
  return Urql.useQuery<ClientQualificationQuery, ClientQualificationQueryVariables>({
    query: ClientQualificationDocument,
    ...options,
  });
}
