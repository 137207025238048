export enum AppEmailTransportError {
  INVALID_APP_EMAIL_TRANSPORT_TYPE = 200001001,
  APP_EMAIL_TRANSPORT_ATTRIBUTE_NOT_SET = 200001002,
}

export enum AppError {
  INVALID_APP_MODE = 200000001,
  TASKS_CLIENT_TYPE_ID_NOT_SET = 200000002,
  CLIENT_TYPE_CAN_NOT_BE_CHOSEN_FOR_TASKS = 200000003,
  TASK_MAX_MEMBER_COUNT = 200000004,
  INVALID_APP_SETTINGS_MODULE_TYPE = 200000005,
  INVALID_APP_SETTINGS_HOOK_CONTEXT = 200000006,
  CAN_NOT_DEACTIVATE_REWORK_STATES_WITH_REWORK_ACTIVE = 200000007,
}

export enum AppModuleError {
  INVALID_APP_MODULE_SETTING_TYPE = 200002001,
}

export enum BillomatError {
  BASE = 700000000,
  CONFIG_NOT_FOUND = 700000001,
  MODULE_DISABLED = 700000002,
  API_KEY_NOT_SET = 700000003,
  BILLOMAT_ID_NOT_SET = 700000004,
  APP_ID_NOT_SET = 700000005,
  APP_SECRET_NOT_SET = 700000006,
}

export enum ChatError {
  CLIENT_ALREADY_MEMBER_OF_CHANNEL = 600003001,
  CLIENT_MUST_BE_MEMBER_OF_CHANNEL = 600003002,
  CLIENT_MUST_BE_OWNER_OF_MESSAGE = 600003003,
  MESSAGE_NOT_RELATED_TO_CHANNEL = 600003004,
  OPERATION_DENIED = 600003004,
  DUPLICATE_CHANNEL_TITLE = 600003005,
}

export enum ClientError {
  INVALID_CLIENT_TYPE = 400000001,
  INVALID_CLIENT_EMAIL_NOTIFICATION_TYPE_ID = 400000002,
  INVALID_CLIENT_ADDITIONAL_INSURANCE_CUSTOMER_STATE = 400000003,
  INVALID_POLICY_HOLDER_TYPE = 400000004,
  INVALID_CLIENT_STATE = 400000005,
  USER_CLIENT_NOT_SET = 400000101,
  EMAIL_IN_USE = 400000102,
  QUALIFICATIONS_DEACTIVATED = 400000103,
  EMAIL_TEMPLATES_NOT_ASSIGNED = 400000104,
  INTERFACE_MESSAGE_TEMPLATES_NOT_ASSIGNED = 400000105,
  CLIENTS_NOT_FOUND = 400000106,
  CLIENT_EMAIL_NOT_SET = 400000107,
  CLIENT_IS_IN_USE = 400001008,
  INSURANCE_IS_IN_USE = 400001009,
  ADJUSTER_CAN_NOT_BE_ASSIGNED = 400001010,
  SIGNUP_NOTIFICATION_DISABLED = 400001011,
}

export enum ExportError {
  INVALID_EXPORT_TYPE = 200003001,
  UNSUPPORTED_EXPORT_TYPE = 200003002,
}

export enum FileClassificationTagError {
  CANT_DELETE_FILE_CLASSIFICATION_TAG_OF_TYPE = 600001001,
  CANT_DELETE_FILE_CLASSIFICATION_TAG_IN_USE = 600001002,
  DUPLICATE_FILE_CLASSIFICATION_TAG_TITLE = 600001003,
  DUPLICATE_FILE_CLASSIFICATION_TAG_ALIAS = 600001004,
  INVALID_FILE_CLASSIFICATION_TAG_TYPE_ID = 600001005,
}

export enum GeneralError {
  BAD_INPUT = 100000001,
  INVALID_INPUT = 100000002,
  NOT_IMPLEMENTED = 100000003,
  FIELD_NOT_SET = 100000004,
  UNEXPECTED = 100000005,
  UNDEFINED = 100000006,
  INVALID_VALUE = 100000007,
}

export enum GeolocationError {
  MAPBOX_ACCESS_TOKEN_NOT_SET = 700001001,
  CANT_GENERATE_MAP_IMAGE = 700001002,
}

export enum HookError {
  INVALID_HOOK_TYPE = 200004001,
}

export enum MagicPlanError {
  CONFIG_NOT_FOUND = 2000,
  API_KEY_NOT_SET = 2001,
  CUSTOMER_ID_NOT_SET = 2002,
  EMAIL_NOT_SET = 2003,
  PROJECT_NOT_INITIALIZED = 2004,
}

export enum SpellCheckError {
  LANGUAGE_NOT_DEFINED = 700002001,
}

export enum TaskCalculationError {
  INVALID_TASK_CALCULATION_OWNER = 500001001,
  INVALID_TASK_CALCULATION_ITEM_OWNER = 500001002,
  INVALID_TASK_CALCULATION_CATEGORY_OWNER = 500001003,
}

export enum TaskError {
  IDENTIFIER_NOT_SET = 500000001,
  DUPLICATE_IDENTIFIER = 500000002,
  CLIENT_TYPE_ID_DOES_NOT_MATCH = 500000003,
  TASK_NOT_ACTIVATED = 500000004,
  CLIENT_NOT_ASSIGNED = 500000005,
  INVALID_TASK_FORM_TYPE = 500000101,
  INVALID_TASK_REPORT_TYPE = 500000102,
  INVALID_TASK_TEMPLATE_TYPE = 500000103,
  INVALID_TASK_VISIT_TYPE = 500000104,
  INVALID_TASK_STATE = 500000105,
  INVALID_TASK_HOOK_CONTEXT = 500000106,
  INVALID_TASK_INVOICE_HOOK_CONTEXT = 500000107,
  INVALID_TASK_FOLDER_TYPE = 500000108,
  INVALID_TASK_MEMBER_TYPE = 500000109,
  INVALID_TASK_REPORT_GENERATION_STATE = 500000109,
  INVALID_TASK_SCOPE_MODE_FOR_CLIENT = 500000110,
  FOLLOW_UP_DATE_IS_IN_THE_PAST = 500000111,
  REMINDER_DATE_IS_IN_THE_PAST = 500000112,
}

export enum TaskFileError {
  CANT_CREATE_TASK_FOLDER = 500002001,
  UNSUPPORTED_EXTENSION = 500002002,
  CANT_DELETE_NONEMPTY_FOLDER = 500002003,
}

export enum TaskFormError {
  CANT_CREATE_TASK_FORM = 500003001,
  CANT_DUPLICATE_TASK_FORM = 500003002,
  CANT_CHANGE_TASK_FORM_TEMPLATE = 500003003,
  CANT_DELETE_TASK_FORM = 500003004,
  CANT_RESTORE_TASK_FORM = 500003005,
  CANT_COPY_TASK_FORM_QUESTIONS = 500003006,
  CANT_UPSERT_TASK_FORM_QUESTION_TABLE_ROW = 500003007,
}

export enum TaskInvoiceError {
  INVALID_TASK_INVOICE_STATE = 500004001,
  CANT_CANCEL_TASK_INVOICE = 500004002,
  CANT_COMPLETE_TASK_INVOICE = 500004003,
  CANT_DELETE_TASK_INVOICE = 500004004,
  CANT_SET_PAID_TASK_INVOICE = 500004005,
  CANT_REVERT_PAID_TASK_INVOICE = 500004006,
  UPDATE_TASK_INVOICE_IN_DRAFT_STATE_ONLY = 500004007,
  UPDATE_TASK_INVOICE_ITEMS_IN_DRAFT_STATE_ONLY = 500004008,
  TASK_INVOICE_TASK_NOT_FOUND = 5000040009,
  TASK_INVOICE_CLIENT_NOT_FOUND = 500004010,
  TASK_INVOICE_FILE_NOT_FOUND = 500004011,
  TASK_INVOICE_ITEMS_MAY_NOT_BE_EMPTY = 500004012,
  TASK_INVOICE_ITEMS_SUN_MAY_NOT_BE_ZERO = 5000040013,
}

export enum TaskLocationError {
  CANT_CREATE_TASK_LOCATION = 500005001,
  CANT_UPDATE_TASK_LOCATION = 500005002,
  CLIENT_ID_MISMATCH = 500005003,
}

export enum TaskLogError {
  TASK_ID_NOT_SET = 500008001,
  CREATED_CLIENT_ID_NOT_SET = 500008002,
  CANT_CREATE_TASK_LOG_FROM_EMAIL = 500008101,
  CANT_CREATE_TASK_LOG_FROM_INTERFACE_ITEM = 500008102,
}

export enum TaskReportError {
  CUSTOM_REPORT_SETTINGS_NOT_SET = 500006001,
  FAILED_TO_CREATE_TASK_REPORT_GENERATION = 500006002,
}

export enum TaskRoomError {
  INVALID_TASK_ROOM_ITEM_ASSIGNMENT = 500007001,
  INVALID_TASK_ROOM_CATEGORY_ASSIGNMENT = 500007002,
}

export enum TaskTemplateError {
  CANT_EDIT_TEMPLATES_IN_USE = 500008001,
  ACTIVE_TEMPLATE_OF_TYPE_NOT_FOUND = 500008002,
  CANT_DEACTIVATE_LAST_ACTIVE_PRIMARY_TEMPLATE = 500008003,
  CANT_DEACTIVATE_LAST_ACTIVE_STATISTICS_TEMPLATE = 500008004,
}

export enum TranscribeError {
  CANT_GENERATE_TEMPORARY_CREDENTIALS = 700003001,
}

export enum VideoError {
  INVALID_CUSTOMER_PORTAL_EVENT_TYPE = 700004001,
  INVALID_CUSTOMER_PORTAL_INVITATION_TYPE = 700004002,
}

export enum AppAllowlistError {
  INVALID_IP_V6_FORMAT = 200005001,
  DUPLICATE_IP = 200005002,
  CAN_NOT_DELETE_CURRENT_IP = 200005003,
  CAN_NOT_ACTIVATE_ALLOWLIST_WITH_EMPTY_LIST = 200005004,
  CAN_NOT_ACTIVATE_ALLOWLIST_WITHOUT_CURRENT_IP = 200005005,
  ACCESS_DENIED = 200005006,
  INTERNAL_ERROR = 200005007,
}
