export default {
  labels: {
    coverTitle: 'Naslovnica',
    tintColor: 'Boja teksta',
    bgDarkColor: 'Boja pozadine (tamno)',
    highlightColor: 'Boja naglaska',
    filename: 'Ime datoteke',
    footer: 'Podnožje',
    footerPaginateTitle: 'Identifikator stranice',
    cover_layout: 'Izgled naslovnice',
    summary_layout: 'Zusammenfassungs-Layout',
    questions_layout: 'Izgled pitanja',
    roomplan_layout: 'Izgled plana sobe',
    images_layout_1: 'Izgled slika 1',
    images_layout_2: 'Izgled slika 2',
    images_layout_4: 'Izgled slika 4',
    calculation_layout: 'Izgleda kalkulacije 4',
    pages: 'Stranice',
    modules: 'Moduli',
    selectedModules: 'Odabrani moduli',
    toc_layout: 'Izgled sadržaja',
    default: 'koristiti kao standard',
    isDefault: 'zadano',
    use_alternate_logo: 'Koristite alternativni logotip',
    hide_primary_form: 'Sakrij primarni upitnik',
    show_notes: 'Prikaži bilješke',
  },
  pages: {
    reportLayoutsOverview: {
      title: 'Predlošci izvješća',
      description: 'Ovdje ćete pronaći popis predložaka izvješća.',
      toolbar: {
        create: 'Kreirajte predložak izvješća',
      },
    },
  },
  dialogs: {
    reportTemplateCreate: {
      title: 'Kreirajte predložak izvješća',
      selectedModules: {
        title: 'Stranice u priručniku',
        instruction:
          'Redoslijed stranica se može promijeniti pomoću <bold>strelica gore/dolje</bold>. Stranice se mogu integrirati u izvješće pomoću <bold>strelice udesno</bold> i ponovno ukloniti pomoću <bold>strelice ulijevo</bold>. <bold>Dvostruke strelice</bold> s desne strane omogućuju dodavanje cijelog sadržaja u izvješće jednim klikom.',
      },
    },
    reportTemplateEdit: {
      title: 'Uredi predložak izvješća',
    },
    delete: {
      title: 'Brisanje predloška izvješća',
      message: 'Želite li stvarno izbrisati predložak izvješća?',
    },
  },
  notifications: {
    createReportLayout: {
      success: 'Predložak izvješća kreiran!',
    },
    editReportLayout: {
      success: 'Predložak izvješća izmjenjen!',
    },
    deleteReportLayout: {
      success: 'Predložak izvješća izbrisan!',
    },
  },
  pageTypes: {
    COVER: 'Naslovnica',
    IMAGES: 'Slike',
    QUESTIONS: 'Pitanja',
    SUMMARY: 'Sažetak',
    CALCULATION: 'Kalkulacija',
    DOCUMENTS: 'Dokumenti',
    ROOM_PLAN: 'Plan sobe',
    FREETEXT: 'slobodan tekst',
    TOC: 'Sadržaj',
  },
  coverLayouts: {
    DEFAULT: 'Default',
    DEFAULT_NO_COVER_IMAGE: 'Nema naslovne slike',
    DEFAULT_INVOICE_CHECK: 'Provjera računa',
    DEFAULT_CLAIMANT_COVER: 'Naslovnica s podnositeljem zahtjeva',
    DEFAULT_RISK_COVER: 'Pokriće rizika',
  },
  summaryLayouts: {
    DEFAULT: 'Default',
    SUBFORMS: 'Subforms',
  },
  roomplanLayouts: {
    DEFAULT: 'Zadano',
  },
  questionLayouts: {
    DEFAULT: 'Zadano',
  },
  imagesLayout1s: {
    DEFAULT: 'Zadano',
  },
  imagesLayout2s: {
    DEFAULT: 'Zadano',
  },
  imagesLayout4s: {
    DEFAULT: 'Zadano',
  },
  calculationLayouts: {
    DEFAULT: 'Zadano',
  },
  tocLayouts: {
    DEFAULT: 'zadano',
  },
};
