import { DropDownListInput } from '@module/shared/forms';
import { useLocale } from '@module/shared/localization';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { useCallback, useEffect, useMemo } from 'react';

import { CountryFragment } from '../../graphql';
import { CountryItem, useCountries, useDropDownListDefaultItem } from '../../hooks';

interface CountrySelectProps extends FieldRenderProps {
  value: string | null;
  onChange: (event: { target?: unknown; value?: string | null }) => void;
  rememberLastCountry?: boolean;
  countries?: CountryFragment[];
  isLoading?: boolean;
}

export const CountrySelect = (props: CountrySelectProps) => {
  const {
    value,
    onChange,
    rememberLastCountry = true,
    countries: countryData,
    isLoadingCountries,
    ...rest
  } = props;
  const { countries, isLoading } = useCountries(countryData, isLoadingCountries);
  const defaultItem = useDropDownListDefaultItem('id', 'title', null);
  const { locale } = useLocale();
  const country = useMemo(() => {
    return countries.find((country) => country.id === value);
  }, [countries, value]);

  const handleChange = useCallback(
    (event: { value?: CountryItem }) => {
      if (rememberLastCountry && event.value?.id) {
        localStorage.setItem('lastRememberedCountry', event.value?.id);
      }
      onChange({
        ...event,
        value: event.value?.id ?? null,
      });
    },
    [onChange, rememberLastCountry],
  );

  useEffect(() => {
    // we need to set lastRememberedCounty only if value is undefined, otherwise we overwrite default values
    if (value === undefined && rememberLastCountry) {
      const lastRememberedCountry = localStorage.getItem('lastRememberedCountry');
      const countryExists = countries.find((country) => country.id === lastRememberedCountry);
      if (countryExists) {
        onChange({
          value: countryExists.id,
        });
      } else {
        const countryExists = countries.find((country) => country.id === locale.id);
        if (countryExists) {
          onChange({
            value: countryExists.id,
          });
        }
      }
    }
  }, [countries, rememberLastCountry, locale, onChange, value]);

  return (
    <DropDownListInput
      {...rest}
      data={countries}
      dataItemKey={'id'}
      isLoading={isLoading}
      textField={'title'}
      defaultItem={defaultItem}
      value={country}
      onChange={handleChange}
    />
  );
};
