export default {
  labels: {
    identifier: 'Rechnungs-Nr.',
    externalNumber: 'Schadennummer',
    date: 'Rechnungsdatum',
    taskId: 'Auftrag',
    address: 'Addresse',
    sumNet: 'Summe Netto',
    sumGross: 'Summe Brutto',
    stateId: 'Status',
    client: 'Kunde',
    note: 'Mahnstatus',
    reminder: 'Mahnung verschickt',
    reminders: 'Mahnungen senden',
    activate: 'Rechnung aktivieren',
    setPaid: 'Als bezahlt markieren',
    revertPaid: 'Als offen markieren',
    cancelInvoice: 'Rechnung stornieren',
    editReminder: 'Mahnstatus bearbeiten',
    payDate: 'Bezahlt am',
    overdueDate: 'Überfällig ab',
    total: 'Gesamt:',
    clients: 'Versicherungen',
    exportDatev: 'Daten als CSV (DATEV) exportieren',
  },
  dialogs: {
    delete: {
      title: 'Rechnung löschen?',
      description: 'Sind Sie sicher, dass Sie die Rechnung löschen wollen?',
    },
    exportDatev: {
      title: 'DATEV Export',
      description: 'Möchten Sie die ausgewählten Rechnung im Datev-Format exportieren?',
      notifications: {
        success: 'Export erfolgreich.',
        error: 'Beim Export ist ein Fehler aufgetreten.',
      },
    },
    multipleNotes: {
      title: 'Mahnungen versenden',
      description: 'Für folgende Rechnung werden mit der ausgewählten Vorlage Mahnungen gesendet:',
      notifications: {
        success: 'Mahnungen erfolgreich versendet.',
        error: 'Mahnungen konnten nicht versendet werden.',
      },

      labels: {
        remove: 'Entfernen',
        template: 'Vorlage wählen',
        send: 'Mahnungen absenden',
        tooltip: 'Entfernt die Rechnung nur aus dieser Liste',
      },
    },
    setPaid: {
      title: 'Rechnung als bezahlt markieren?',
      description: 'Sind Sie sicher, dass Sie die Rechnung als bezahlt markieren wollen?',
    },
    revertPaid: {
      title: 'Rechnung als offen markieren?',
      description: 'Sind Sie sicher, dass Sie die Rechnung als offen markieren wollen?',
    },
    cancelInvoice: {
      title: 'Rechnung stornieren?',
      description: 'Sind Sie sicher, dass Sie die Rechnung stornieren wollen?',
    },
    invoiceExport: {
      title: 'Rechnungen exportieren',
      note: 'Laden Sie die exportierten Rechnungen herunter.',
    },
    editReminder: {
      title: 'Mahnstatus',
      reminder_note: 'Mahnstatus bearbeiten',
      reminder: 'Mahnung verschickt',
      notifications: {
        error: 'Mahnstatus konnte leider nicht geändert werden.',
        success: 'Mahnstatus erfolgreich geändert.',
      },
    },
    payDate: 'Bezahlt am',
    overdueDate: 'Zahlungsziel',
  },
  pages: {
    overview: {
      title: 'Rechnungen',
      description:
        'Hier sehen Sie eine Übersicht aller vom System erzeugten Rechnungen. Sie können über den Aktionen Button die Rechnungen öffnen.',
    },
  },
};
